import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React from "react";
import { stringToTitleCase } from "../helpers";

export default function MaturityStageCard({ value, stage, maturityMappings }) {
  return (
    <Card sx={{ maxWidth: "100%", minWidth: "100%", display: "flex" }}>
      <CardContent
        sx={{
          maxWidth: "100%",
          minWidth: "100%",
          display: "flex",
          justifyContent: "space-between",
          p: 4,
        }}
      >
        {maturityMappings?.map((currStage) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              key={currStage._id}
            >
              <Typography variant="h4" component="div">
                {stringToTitleCase(currStage.stage)}
              </Typography>
              <Box>
                {String(currStage.stage) === String(stage).toLowerCase() ? (
                  <Tooltip
                    title={`Maturity score: ${value}`}
                    placement="bottom"
                    arrow
                    enterTouchDelay={100}
                  >
                    <CheckCircleIcon
                      sx={{ m: 1, color: "#00684b", fontSize: 35 }}
                    />
                  </Tooltip>
                ) : (
                  <CircleOutlinedIcon
                    sx={{ m: 1, color: "#00684b", fontSize: 35 }}
                  />
                )}
              </Box>
            </Box>
          );
        })}
      </CardContent>
    </Card>
  );
}
