import { useOktaAuth } from "@okta/okta-react";
import React, { useContext, useEffect, useState } from "react";
import * as Realm from "realm-web";
import configData from "../config.json";

export const RealmContext = React.createContext();

export function useRealmUserContext() {
  return useContext(RealmContext);
}

export default function RealmAuthContext({ children }) {
  const { authState } = useOktaAuth();
  const [currentUser, setCurrentUser] = useState();
  const [db, setDB] = useState();
  const [maturityMappings, setMaturityMappings] = useState();
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState(null);

  useEffect(
    () => {
      const jwtLogin = async () => {
        try {
          // Authenticate the user
          const app = new Realm.App({ id: configData.APP_ID });
          const credentials = Realm.Credentials.jwt(authState.idToken.idToken);
          const user = await app.logIn(credentials);
          await user.refreshCustomData(); // Ensure custom data isn't stale
          const mongo = app.currentUser.mongoClient("mongodb-atlas");
          const db = mongo.db("athena");
          setDB(db);
          setCurrentUser(user);
        } catch (err) {
          console.error("Failed to log in", err);
        }
      };
      if (!currentUser && authState) {
        jwtLogin().then(() => setLoading(false));
      }
    },
    [authState, currentUser] // empty array as authState seems to trigger re-render
  );

  useEffect(() => {
    const setMappings = async () => {
      const mappingsColl = db.collection("maturityScoreMappings");
      const mappings = await mappingsColl.find(
        {},
        { sort: { numericStage: 1 } }
      );
      setMaturityMappings(mappings);
    };

    if (db) {
      setMappings();
    }
  }, [db]);

  const value = {
    currentUser,
    db,
    loading,
    maturityMappings,
    snackbar,
    setSnackbar,
  };

  return (
    <RealmContext.Provider value={value}>{children}</RealmContext.Provider>
  );
}
