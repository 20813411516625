import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { stringToTitleCase } from "../helpers";

export default function SuggestedActionSubCard({
  category,
  maturityMappings,
  highlightColor = "#00684A",
  textColor = "white",
}) {
  const mapPrioritiesToString = (priority) => {
    const prioMap = {
      1: "Critical",
      2: "Important",
      3: "Nice to have",
    };
    return prioMap[priority];
  };
  return (
    <Grid item xs={12} sx={{ mt: 2 }}>
      <Accordion xs={4}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: textColor }} />}
          sx={{
            backgroundColor: highlightColor,
            color: textColor,
            alignItems: "center",
          }}
        >
          <Box display="flex" sx={{ alignItems: "center", color: textColor }}>
            <Typography variant="h5" sx={{ color: textColor }}>
              {category.name}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ pt: 2 }}>
            <Typography variant="body.1">
              <span style={{ fontWeight: "bold" }}>Description: </span>
              {category.description}
            </Typography>
          </Box>
          <Box sx={{ pt: 1 }}>
            <Typography variant="body.2">
              <span style={{ fontWeight: "bold" }}>Importance: </span>
              {mapPrioritiesToString(category.catMapping.priority)}
            </Typography>
          </Box>
          <TableContainer component={Paper} sx={{ pt: 2 }}>
            <Table
              sx={{ color: "#001E2B", border: 1 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: "#00684A" }}>
                  {maturityMappings &&
                    maturityMappings.map((mapping) => {
                      return (
                        <TableCell key={mapping.stage} sx={{ border: 1 }}>
                          <Typography color="white" variant="h6">
                            {stringToTitleCase(mapping.stage)}
                          </Typography>
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {category.answers.map((answer) => {
                    return (
                      <TableCell
                        key={answer.answerLevel}
                        sx={{
                          border:
                            answer.answerLevel === category.surveyScore ? 5 : 1,
                          borderColor:
                            answer.answerLevel === category.surveyScore
                              ? "#00ED64"
                              : "black",
                        }}
                      >
                        {answer.answerText}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            <Typography variant="h6" sx={{ pt: 3 }}>
              Playbooks:
            </Typography>
            <List sx={{ listStyleType: "disc", ml: 2 }}>
              {category.resources.map((resource) => {
                return (
                  <ListItem key={resource.text}>
                    {resource.url ? (
                      <Link
                        target="_blank"
                        to={{
                          pathname: resource.url,
                        }}
                      >
                        {resource.text}
                      </Link>
                    ) : (
                      <Typography>{resource.text}</Typography>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
