import { InfoOutlined } from "@mui/icons-material";
import {
  Button,
  CardContent,
  Container,
  Tooltip,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import MaturityStageAdmin from "../components/MaturityStageAdmin";
import { useRealmUserContext } from "../contexts/RealmContext";

export default function AdminPage() {
  const { loading, db, currentUser, setSnackbar } = useRealmUserContext();

  const [loadingData, setLoadingData] = useState(true);

  const [categories, setCategories] = useState();
  const [maturityStageMappings, setMaturityStageMappings] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const fetchCategories = async () => {
      const customersColl = db.collection("categories");
      customersColl.find({}).then((results) => {
        setCategories(results);
      });
    };

    const fetchMaturityMappings = async () => {
      const maturityMappingsColl = db.collection("maturityScoreMappings");
      maturityMappingsColl
        .find({}, { sort: { numericStage: 1 } })
        .then((results) => {
          setMaturityStageMappings(results);
        });
    };

    if (!loading) {
      if (!currentUser.customData.is_admin) {
        setSnackbar({
          children: "Unauthorized user",
          severity: "error",
        });
        history.push("/");
      } else {
        Promise.all([fetchCategories(), fetchMaturityMappings()]).then(() => {
          setLoadingData(false);
        });
      }
    }
  }, [loading, db, currentUser?.customData?.is_admin, setSnackbar, history]);

  return (
    <>
      <Container
        sx={{
          pt: 4,
          height: "90vh",
        }}
        maxWidth="lg"
      >
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          spacing={2}
        >
          <Grid
            item
            display="flex"
            justifyContent="flex-start"
            alignItems="start"
          >
            <Breadcrumbs sx={{ color: "#001e2b" }} aria-label="breadcrumb">
              <Link
                underline="hover"
                color="#001e2b"
                component={RouterLink}
                to="/"
              >
                Home
              </Link>
              <Link
                underline="hover"
                color="#001e2b"
                component={RouterLink}
                to="/admin"
              >
                Admin
              </Link>
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="start"
            alignItems="start"
          >
            <Typography variant="h3" gutterBottom>
              Admin
            </Typography>
          </Grid>
          {loadingData ? (
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography variant="h4">Categories</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          color="mdbGreen"
                          onClick={() => {
                            alert("not implemented");
                          }}
                        >
                          Add Category
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Table
                          sx={{ minWidth: 250, color: "#001E2B" }}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ color: "#001E2B" }}>
                                <Typography variant="h6">Name</Typography>
                              </TableCell>
                              <TableCell sx={{ color: "#001E2B" }}>
                                <Typography variant="h6">Weight</Typography>
                              </TableCell>
                              <TableCell
                                sx={{ color: "#001E2B" }}
                                align="right"
                              ></TableCell>
                              <TableCell
                                sx={{ color: "#001E2B" }}
                                align="right"
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {categories &&
                              categories.map((category) => {
                                return (
                                  <TableRow
                                    key={category._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        color: "#001E2B",
                                        fontSize: "1.1em",
                                      }}
                                      component="th"
                                      scope="row"
                                    >
                                      {category._id}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#001E2B",
                                        fontSize: "1.1em",
                                      }}
                                      component="th"
                                      scope="row"
                                    >
                                      {category.weight}
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        variant="contained"
                                        color="mdbGreen"
                                        size="small"
                                        onClick={() => {
                                          history.push(
                                            `/admin/${encodeURIComponent(
                                              category._id
                                            )}`
                                          );
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          alert("Not implemented");
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid
                      container
                      display="flex"
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <Grid item xs={12} display="flex" alignItems="center">
                        <Typography variant="h4">
                          Maturity Stage Mappings
                        </Typography>
                        <Tooltip
                          title="Define how numeric maturity scores are mapped to stages"
                          placement="top"
                          arrow
                          enterTouchDelay={100}
                        >
                          <InfoOutlined
                            color="mdbGreen"
                            sx={{ ml: 1, mt: 0.5 }}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {!loadingData && (
                        <MaturityStageAdmin
                          maturityStageMappings={maturityStageMappings}
                          setMaturityStageMappings={setMaturityStageMappings}
                        ></MaturityStageAdmin>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
