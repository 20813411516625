import styled from "@emotion/styled";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Card, CardContent, Slider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useEffect, useState } from "react";
import { useRealmUserContext } from "../contexts/RealmContext";

const MappingSlider = styled(Slider)`
.MuiSlider-valueLabelOpen: {
  backgroundColor: "#2a2a2a"
}
 & .MuiSlider-thumb {
    border-radius: 5px;
    width: 5px;
    height: 25px

  }

,
`;

export default function MaturityStageAdmin({
  maturityStageMappings,
  setMaturityStageMappings,
}) {
  const { setSnackbar, db } = useRealmUserContext();

  const [mappingVals, setMappingVals] = useState([]);

  useEffect(() => {
    const mappingsArray = maturityStageMappings.map((val) => {
      return val.minScore;
    });
    mappingsArray.push(101);
    setMappingVals(mappingsArray);
  }, [maturityStageMappings, setMappingVals]);

  const minDistance = 2;

  const handleMappingChange = async (_, newValue, activeThumb) => {
    const tmpMappings = [...mappingVals];
    newValue[0] = 0;
    newValue[newValue.length - 1] = 101;
    tmpMappings.forEach((value, idx) => {
      if (activeThumb === idx) {
        tmpMappings[idx] = Math.min(
          newValue[idx],
          tmpMappings[idx + 1] - minDistance,
          tmpMappings[idx + 1]
        );

        if (tmpMappings[idx] === tmpMappings[idx - 1]) {
          tmpMappings[idx] += minDistance;
        }

        tmpMappings[tmpMappings.length - 1] = 101;
      }
    });
    setMappingVals(tmpMappings);
  };

  const saveMappings = async () => {
    const tmpMappings = [...maturityStageMappings];
    tmpMappings.forEach((value, idx) => {
      maturityStageMappings[idx].minScore = mappingVals[idx];

      const maxVal =
        idx === tmpMappings.length - 1
          ? mappingVals[idx + 1]
          : mappingVals[idx + 1] - 1;
      maturityStageMappings[idx].maxScore = maxVal;
    });

    try {
      await updateMappingsInDB(maturityStageMappings);
      setMaturityStageMappings(maturityStageMappings);
      setSnackbar({ children: "Saved!", severity: "success" });
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Couldn't write to DB",
        severity: "error",
      });
    }
  };

  const updateMappingsInDB = async (mappings) => {
    const maturityMappingsColl = db.collection("maturityScoreMappings");

    for (const mapping of mappings) {
      maturityMappingsColl.updateOne(
        {
          _id: mapping._id,
          stage: mapping.stage,
        },
        {
          $set: {
            minScore: mapping.minScore,
            maxScore: mapping.maxScore,
          },
        }
      );
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid
          container
          display="flex"
          xs={12}
          justifyContent="space"
          alignItems="flex-start"
        >
          <Grid item xs={2} sx={{ textAlign: "start" }}>
            <Typography>Prospect</Typography>
          </Grid>
          <Grid item xs={1} sx={{ textAlign: "center" }}>
            <ArrowForwardIcon />
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <Typography>Adoption</Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <ArrowForwardIcon />
          </Grid>

          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <Typography>Maturation</Typography>
          </Grid>
          <Grid item xs={1} sx={{ textAlign: "center" }}>
            <ArrowForwardIcon />
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <Typography>Advocacy</Typography>
          </Grid>
          <Grid item xs={12} pt={10} pb={5} mx={2}>
            <MappingSlider
              xs={10}
              disableSwap
              value={mappingVals}
              max={101}
              onChange={handleMappingChange}
              valueLabelDisplay="on"
            ></MappingSlider>
          </Grid>
        </Grid>
        <Grid item display="flex" justifyContent="flex-end">
          <Button
            size="small"
            variant="contained"
            color="mdbGreen"
            onClick={saveMappings}
          >
            Save
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
}
