import { Button, Container, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import * as Realm from "realm-web";
import CategoryCard from "../components/CategoryCard";
import { useRealmUserContext } from "../contexts/RealmContext";

export default function SurveyPage() {
  /* 
    This page shows a the Survey UI, It has two major functions, 
    if the URL contains a survey ID it turns in to a read-only page to show the result of a survey. 
    Admins or the owner of the survey can delete it.
    If there is no survey id, it allows the user to submit a new survey.
  */

  const { currentUser, db, loading, setSnackbar } = useRealmUserContext();
  const [loadingData, setLoadingData] = useState(true);

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [customer] = useState(decodeURIComponent(useParams().customerName));

  const [surveyId] = useState(useParams().surveyId);

  const [categories, setCategories] = useState();
  const [disabled, setDisabled] = useState(false); // State to hold if the form is disabled or not

  const [owner, setOwner] = useState(false);

  const [answers, setAnswers] = useState({
    customer: customer,
    responses: [],
  });

  const history = useHistory();

  useEffect(() => {
    // Fetch all of the Categories, these are then passed down to the CategoryCards.
    // If user is viewing results, fetch results.
    if (!loading) {
      if (surveyId) {
        const surveyColl = db.collection("surveyResultsRaw");
        surveyColl
          .findOne({
            _id: new Realm.BSON.ObjectId(surveyId),
          })
          .then((results) => {
            setAnswers(results);
            setDisabled(true);
          });
      }
      const categoriesColl = db.collection("categories");
      categoriesColl.find({}).then((results) => {
        setCategories(results);
        setLoadingData(false);
      });
    }
  }, [loading, db, surveyId]);

  useEffect(() => {
    if (!loading && answers && answers.owner === currentUser.profile.name) {
      setOwner(currentUser.profile.name);
    }
  }, [currentUser?.profile?.name, answers, loading]);

  function handleAnswers(answer) {
    // Function to handle answers from the Child CategoryCard components
    if (!surveyId) {
      let tempAnswers = { ...answers };
      tempAnswers.responses = tempAnswers.responses.filter(
        (entry) => entry.category !== answer.category
      );
      tempAnswers.responses.push(answer);
      setAnswers(tempAnswers);

      setCurrentQuestion(currentQuestion + 1);
    }
  }

  function handleSubmit() {
    // Function to handle submit of all of the answers to the backend. Checks to se that all categories has answers
    if (answers.responses.length < categories.length) {
      setSnackbar({
        children: "You need to answer all questions before submitting",
        severity: "error",
      });
    } else {
      currentUser.functions.submitSurvey(answers).then((result) => {
        history.push(`/customer/${encodeURIComponent(customer)}`);
        setSnackbar({
          children: "Survey successfully submitted",
          severity: "success",
        });
        // TODO: Handle error of submitting a survey
      });
    }
  }

  const handleDelete = async () => {
    if (
      (owner === currentUser.profile.name || currentUser.customData.is_admin) &&
      surveyId
    ) {
      const surveyColl = db.collection("surveyResultsRaw");
      await surveyColl.deleteOne({
        _id: new Realm.BSON.ObjectId(surveyId),
      });

      const pipeline = [
        {
          $match: {
            customer: "001A000001EA4LvIAL",
          },
        },
        {
          $sort: {
            timestamp: -1,
          },
        },
        {
          $limit: 1,
        },
      ];

      const newLastDate = await surveyColl.aggregate(pipeline);
      if (newLastDate.length > 0) {
        db.collection("customers").updateOne(
          { sfdc_account_id: customer },
          { $set: { last_survey: newLastDate[0].timestamp } }
        );
      } else {
        db.collection("customers").updateOne(
          { sfdc_account_id: customer },
          { $unset: { last_survey: "" } }
        );
      }
      history.push(`/customer/${encodeURIComponent(customer)}`);
      setSnackbar({
        children: "Survey successfully deleted",
        severity: "success",
      });
    }
  };

  return (
    <>
      <Container
        sx={{
          pt: 4,
          height: "90vh",
        }}
        maxWidth="lg"
      >
        <Grid container spacing={2}>
          <Grid item justifyContent="center" alignItems="center">
            <Breadcrumbs sx={{ color: "#001e2b" }} aria-label="breadcrumb">
              <Link
                component={RouterLink}
                underline="hover"
                color="#001e2b"
                to="/"
              >
                Home
              </Link>
              <Link
                component={RouterLink}
                underline="hover"
                color="#001e2b"
                to={`/customer/${encodeURIComponent(customer)}`}
              >
                {customer}
              </Link>
              {surveyId && (
                <Link
                  component={RouterLink}
                  underline="hover"
                  color="#001e2b"
                  to={`/${encodeURIComponent(
                    customer
                  )}/survey/${encodeURIComponent(surveyId)}`}
                >
                  Survey {surveyId}
                </Link>
              )}
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="start"
            alignItems="start"
          >
            <Typography sx={{ color: "#001e2b" }} variant="h3" gutterBottom>
              New Survey
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {loadingData ? (
              <Grid
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            ) : (
              categories.map((category) => {
                return (
                  <CategoryCard
                    key={category._id}
                    category={category}
                    handleAnswers={handleAnswers}
                    answers={answers}
                    disabled={disabled}
                  />
                );
              })
            )}
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          ></Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {surveyId ? (
              <>
                {(owner === currentUser?.profile?.name ||
                  currentUser?.customData?.is_admin) && (
                  <Button
                    color="error"
                    variant="contained"
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                )}
              </>
            ) : (
              <Button
                variant="contained"
                color="mdbGreen"
                onClick={handleSubmit}
              >
                Submit survey
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
