import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import React, { useCallback } from "react";
import { Route, BrowserRouter as Router, useHistory } from "react-router-dom";
import AppComp from "./components/AppComp";
import configData from "./config.json";

import Customer from "./pages/Customer";
import HomePage from "./pages/HomePage";
import SurveyPage from "./pages/SurveyPage";

import RealmContext from "./contexts/RealmContext";
import AdminPage from "./pages/AdminPage";
import EditCategoryPage from "./pages/EditCategoryPage";
import EditQuestionPage from "./pages/EditQuestionPage";

const oktaAuth = new OktaAuth({
  issuer: configData.ISSUER,
  clientId: configData.CLIENT_ID,
  redirectUri: window.location.origin + "/login/callback",
});

const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#001E2B",
    },
    secondary: {
      main: "#00ED64",
    },
    background: {
      default: "#E3FCF7",
      paper: "#FFF",
    },
    mdbGreen: {
      main: "#00684b",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Euclid Circular A"],
    h1: {
      fontFamily: "MongoDB Value Serif",
      color: "#00684b",
    },
    h2: {
      fontFamily: "MongoDB Value Serif",
      color: "#00684b",
    },
    h3: {
      fontFamily: "MongoDB Value Serif",
      color: "#00684b",
    },
    h4: {
      fontFamily: "MongoDB Value Serif",
      color: "#00684b",
    },
    h5: {
      fontFamily: "MongoDB Value Serif",
      color: "#00684b",
    },
    h6: {
      fontFamily: "MongoDB Value Serif",
      color: "#00684b",
    },
  },
});

const App = () => {
  const history = useHistory();
  const restoreOriginalUri = useCallback(
    (_, originalUri) => {
      history.replace(
        toRelativeUrl(originalUri || "/", window.location.origin)
      );
    },
    [history]
  );

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      responseMode="fragment"
    >
      <ThemeProvider theme={darkTheme}>
        <RealmContext>
          <CssBaseline />

          <AppComp />

          <SecureRoute path="/" exact={true} children={<HomePage />} />
          <SecureRoute path="/customer/:customerId" children={<Customer />} />
          <SecureRoute
            path="/:customerName/survey/:surveyId"
            children={<SurveyPage />}
          />
          <SecureRoute
            path="/:customerName/survey/"
            exact={true}
            children={<SurveyPage />}
          />
          <SecureRoute path="/admin" exact={true} children={<AdminPage />} />
          <SecureRoute
            exact={true}
            path="/admin/:categoryId/:questionId"
            children={<EditQuestionPage />}
          />
          <SecureRoute
            exact={true}
            path="/admin/:categoryId"
            children={<EditCategoryPage />}
          />

          <Route path="/login/callback" component={LoginCallback} />
        </RealmContext>
      </ThemeProvider>
    </Security>
  );
};

const AppWithRouterAccess = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouterAccess;
