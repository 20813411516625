import { Button, Tooltip, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { calculatePenetration } from "../helpers";

export default function AccountTable({ loadingData, customers }) {
  const history = useHistory();
  return (
    <>
      {loadingData ? (
        ""
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, color: "#001E2B" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "#001E2B" }}>Account</TableCell>
                <TableCell sx={{ color: "#001E2B" }}>Owner</TableCell>
                <TableCell sx={{ color: "#001E2B" }}>Last survey</TableCell>
                <TableCell sx={{ color: "#001E2B" }}>Maturity Stage</TableCell>
                <TableCell sx={{ color: "#001E2B" }} align="right">
                  TAMM
                </TableCell>
                <TableCell sx={{ color: "#001E2B" }} align="right">
                  ARR
                </TableCell>
                <TableCell sx={{ color: "#001E2B" }} align="right">
                  Penetration
                </TableCell>
                <TableCell sx={{ color: "#001E2B" }} align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers &&
                customers.map((cust) => {
                  return (
                    <TableRow
                      key={cust.sfdc_account_id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        sx={{ color: "#001E2B" }}
                        component="th"
                        scope="row"
                      >
                        <Button
                          variant="outlined"
                          color="mdbGreen"
                          sx={{ width: "250px" }}
                          onClick={() => {
                            history.push(
                              "/customer/" +
                                encodeURIComponent(cust.sfdc_account_id)
                            );
                          }}
                        >
                          {cust.name}
                        </Button>
                      </TableCell>
                      <TableCell sx={{ color: "#001E2B" }}>
                        {cust.owner.name}
                      </TableCell>
                      <TableCell sx={{ color: "#001E2B" }}>
                        {cust.last_survey ? (
                          <Moment fromNow>{cust.last_survey}</Moment>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell sx={{ color: "#001E2B" }}>
                        <Tooltip
                          title={cust.maturityScore}
                          placement="top"
                          arrow
                          enterTouchDelay={100}
                        >
                          <Typography variant="body.1">
                            {cust?.maturityStage}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ color: "#001E2B" }} align="right">
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          trailingZeroDisplay: "stripIfInteger",
                        }).format(Math.round(cust.metrics.adjusted_tamm))}
                      </TableCell>
                      <TableCell sx={{ color: "#001E2B" }} align="right">
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          trailingZeroDisplay: "stripIfInteger",
                        }).format(Math.round(cust.metrics.arr))}
                      </TableCell>
                      <TableCell sx={{ color: "#001E2B" }} align="right">
                        {calculatePenetration(
                          cust?.metrics?.adjusted_tamm,
                          cust?.metrics?.arr
                        )}
                        %
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="mdbGreen"
                          onClick={() => {
                            history.push(
                              `/${encodeURIComponent(
                                cust.sfdc_account_id
                              )}/survey/`
                            );
                          }}
                        >
                          Run new survey
                        </Button>
                      </TableCell>
                      {/* <TableCell sx={{ color: "#001E2B" }} align="right">
                    {maturityStage}
                  </TableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
