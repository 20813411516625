import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useRealmUserContext } from "../contexts/RealmContext";
import { stringToTitleCase } from "../helpers";
import SuggestedActionSubCard from "./SuggestedActionSubCard";

const StyledAccordionSumary = styled(AccordionSummary)({
  ".MuiAccordionSummary-content": {
    justifyContent: "center",
  },
});

const scoreMap = {
  0: "Prospect",
  1: "Adoption",
  2: "Maturation",
  3: "Advocacy",
};

const prioMap = {
  1: "Critical",
  2: "Important",
  3: "Nice to have",
};

const mapPrioritiesToString = (priority) => {
  return prioMap[priority];
};

function getKeyByValue(object, value) {
  const val = Object.keys(object).find((key) => object[key] === value);
  return val;
}

export default function SuggestedActionsCard({
  maturityStage,
  latestSurvey,
  maturityMappings,
}) {
  const { loading, db } = useRealmUserContext();
  const [categoryActions, setCategoryActions] = useState([]);

  useEffect(() => {
    const fetchCategoryActions = async () => {
      const categoriesColl = db.collection("categories");
      const pipeline = [
        {
          $match: {
            priorityMapping: {
              $elemMatch: {
                stage: maturityStage.toLowerCase(),
              },
            },
          },
        },
        {
          $unwind: "$questions",
        },
        {
          $unwind: "$questions.possibleAnswers",
        },
        {
          $sort: {
            "questions.possibleAnswers.answerLevel": 1,
          },
        },
        {
          $group: {
            _id: {
              name: "$_id",
              description: "$description",
              resources: "$resources",
              priorityMapping: "$priorityMapping",
            },
            answers: { $push: "$questions.possibleAnswers" },
            sum: {
              $sum: 1,
            },
          },
        },
        {
          $project: {
            _id: 0,
            name: "$_id.name",
            resources: "$_id.resources",
            description: "$_id.description",
            answers: 1,
            catMapping: {
              $first: {
                $filter: {
                  input: "$_id.priorityMapping",
                  as: "pm",
                  cond: {
                    $eq: ["$$pm.stage", maturityStage.toLowerCase()],
                  },
                },
              },
            },
          },
        },
      ];

      const results = await categoriesColl.aggregate(pipeline);

      const maturityStageNumericUnweighted = getKeyByValue(
        scoreMap,
        stringToTitleCase(maturityStage)
      );
      const actionableCategories = [];

      for (const category of results) {
        const categoryScore = latestSurvey.responses.find(
          (resp) => resp.category === category.name
        ).unweightedScore;

        const categoryMaturityDelta =
          maturityStageNumericUnweighted - categoryScore;
        category.surveyScore = categoryScore;
        category.maturityDelta = categoryMaturityDelta;

        // Ignore categories where the category score is equal to or more than the overall maturity score
        if (category.maturityDelta >= 0) {
          actionableCategories.push(category);
        }
      }
      actionableCategories.sort(
        (item1, item2) =>
          item1.catMapping.priority - item2.catMapping.priority ||
          item2.maturityDelta - item1.maturityDelta
      );
      setCategoryActions(actionableCategories);
    };
    if (maturityStage) {
      if (!loading && maturityStage && latestSurvey) {
        fetchCategoryActions();
      }
    }
  }, [db, latestSurvey, loading, maturityStage]);

  return (
    <Accordion defaultExpanded>
      <StyledAccordionSumary
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        sx={{ backgroundColor: "#00684A" }}
      >
        <Typography sx={{ color: "white", display: "flex" }} variant="h4">
          Focus Areas
        </Typography>
      </StyledAccordionSumary>
      <AccordionDetails>
        <Typography sx={{ color: "#001e2b" }} variant="body">
          Based on your most recent survey answers for this customer and the
          account metrics (arr, tamm, penetration), we've assessed an overall
          maturity stage of "{maturityStage}". Here you can find some suggested
          actions/resources to progress the customer on their MongoDB journey.
        </Typography>
        {Object.values(prioMap).map((prio) => {
          return (
            categoryActions.filter(
              (category) =>
                mapPrioritiesToString(category.catMapping.priority) === prio
            ).length > 0 && (
              <Grid container spacing={2} key={prio}>
                <Accordion xs={4} defaultExpanded={prio === "Critical"}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
                    sx={{
                      backgroundColor: "#F9EBFF",
                      color: "black",
                      alignItems: "center",
                    }}
                  >
                    <Box display="flex" sx={{ alignItems: "center" }}>
                      {prio === "Critical" && (
                        <WarningAmberOutlinedIcon sx={{ mr: 2 }} />
                      )}
                      <Typography variant="h5" sx={{ color: "black" }}>
                        {prio}
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    {categoryActions
                      .filter(
                        (category) =>
                          mapPrioritiesToString(
                            category.catMapping.priority
                          ) === prio
                      )
                      .map((category) => {
                        return (
                          <SuggestedActionSubCard
                            key={category.name}
                            category={category}
                            maturityMappings={maturityMappings}
                            highlightColor="#E3FCF7"
                            textColor="black"
                          />
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
