import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

export default function SurveyHistoryCard({ surveys }) {
  const [series, setSeries] = useState();

  const options = {
    xaxis: {
      type: "datetime",
    },
    colors: ["#00684A"],
    tooltip: {
      enabled: false,
    },
    yaxis: {
      title: {
        text: "Score",
      },
    },
    stroke: {
      curve: "smooth",
    },
    chart: {
      selection: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.4,
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },

    dataLabels: {
      enabled: true,
    },
  };

  useEffect(() => {
    const output = [];
    surveys?.forEach((element) => {
      output.push([element.timestamp, element.maturityScore]);
    });
    if (surveys?.length > 0) {
      setSeries({ data: output, name: "score" });
    }
  }, [surveys]);

  if (series) {
    return (
      <Card sx={{ maxWidth: "100%", minWidth: "100%", display: "flex" }}>
        <CardContent sx={{ flex: "1", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "top",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6">Maturity Score over time</Typography>
          </Box>
          <Box>
            <Chart
              options={options}
              series={[series]}
              type="line"
              width="100%"
              height={200}
            />
          </Box>
        </CardContent>
      </Card>
    );
  }
}
