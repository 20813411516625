export const getMaturityStage = (mapping, numericValue, metrics) => {
  const result = mapping.find(
    (item) => numericValue >= item.minScore && numericValue <= item.maxScore
  );
  const penetration = calculatePenetration(metrics.adjusted_tamm, metrics.arr);
  let stage = stringToTitleCase(result.stage);
  let finalStage = "";

  if (metrics.arr <= 0) {
    stage = "Prospect";
  } else if (stage === "Advocacy" && penetration < 70) {
    finalStage = "Maturation";
  } else if (stage === "Maturation" && penetration < 30) {
    finalStage = "Adoption";
  } else {
    finalStage = stage;
  }

  return finalStage;
};
export const stringToTitleCase = (title) => {
  return title[0].toUpperCase() + title.slice(1);
};

export const calculatePenetration = (tamm, arr) => {
  if (!tamm || !arr) {
    return 0;
  }
  return Math.ceil((arr / tamm) * 100);
};
