import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import React from "react";

export default function MaturityMatrixCard({ maturityMatrix }) {
  const backgroundColors = ["#e06666", "#f8b46c", "#dfefe3", "#81ce91"];
  return (
    <Card sx={{ maxWidth: "100%", minWidth: "100%", display: "flex" }}>
      <CardContent
        sx={{
          maxWidth: "100%",
          minWidth: "100%",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
            <Typography textAlign="center" variant="h4">
              Account Maturity Matrix
            </Typography>
          </Grid>
          {maturityMatrix.map((category) => {
            return (
              <Grid
                item
                xs={3}
                md={3}
                lg={3}
                key={category.category}
                boxShadow="0px 0px 0 1px"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    alignContent: "center",
                    backgroundColor: backgroundColors[category.unweightedScore],
                    maxWidth: "100%",
                    minWidth: "100%",
                    minHeight: "100px",
                  }}
                  key={category.category}
                >
                  <Typography textAlign="center" variant="body" color={"black"}>
                    {category.category}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
          <Grid
            item
            justifyContent="center"
            xs={12}
            md={12}
            lg={12}
            sx={{ display: "flex", mt: 2 }}
          >
            <span style={{ marginRight: 5 }}>Prospect</span>
            <img
              border="1px solid"
              style={{ maxWidth: "100px" }}
              src="/assets/legend.png"
              alt="Legend"
            />
            <span style={{ marginLeft: 5 }}>Advocacy</span>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
