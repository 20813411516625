import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { debounce } from "@mui/material/utils";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import configData from "../config.json";
import { useRealmUserContext } from "../contexts/RealmContext";

export default function AccountSearch() {
  const [options, setOptions] = React.useState([
    { name: "No data", sfdc_account_id: "", owner: { name: "" } },
  ]);
  const [customer] = React.useState("");

  const history = useHistory();

  const { db } = useRealmUserContext(); // Realm context

  function onCustomerClick(_, customer) {
    history.push(`/customer/${encodeURIComponent(customer.id)}`);
  }

  const onInputChange = useCallback(
    (_, value) => {
      if (value) {
        db.collection(configData.CUSTOMERS_COLLECTION)
          .aggregate([
            {
              $search: {
                autocomplete: {
                  query: value,
                  path: "name",
                  tokenOrder: "any",
                },
              },
            },
            { $limit: 15 },
            {
              $project: {
                name: 1,
                sfdc_account_id: 1,
                metrics: 1,
                owner: 1,
                _id: 0,
                score: { $meta: "searchScore" },
              },
            },
            {
              $sort: { "metrics.arr": -1 },
            },
          ])
          .then((results) => {
            setOptions(results);
          });
      }
    },
    [db]
  );

  const searchDelayed = React.useMemo(
    () => debounce(onInputChange, 300),
    [onInputChange]
  );

  return (
    <Autocomplete
      sx={{ minWidth: "250px", backgroundColor: "#FFF" }}
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      value={customer}
      onChange={onCustomerClick}
      filterOptions={(x) => x}
      onInputChange={searchDelayed}
      options={options.map((option) => ({
        label: option.name,
        id: option.sfdc_account_id,
        owner: option.owner.name,
      }))}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Box sx={{ flexGrow: 1 }} id={option.id}>
            <Typography sx={{ fontWeight: "bold" }}>{option.label}</Typography>
            <Typography sx={{ fontWeight: "light" }}>{option.owner}</Typography>
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          ref={params.InputProps.ref}
          {...params}
          label="Search account"
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
        />
      )}
    />
  );
}
