import {
  Alert,
  AlertTitle,
  Button,
  CardContent,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import MuiInput from "@mui/material/Input";
import Link from "@mui/material/Link";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import TitleWithToolTip from "../components/TitleWithToolTip";
import { useRealmUserContext } from "../contexts/RealmContext";

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function EditCategoryPage() {
  const currCategory = useParams().categoryId;

  const { loading, db, setSnackbar, currentUser } = useRealmUserContext();

  const [loadingData, setLoadingData] = useState(true);

  const [category, setCategory] = useState();
  const [errors, setErrors] = useState([]);
  const [description, setDescription] = useState({ dirty: false });
  const [weight, setWeight] = useState({ dirty: false });
  const [resources, setResources] = useState({ dirty: false });

  const history = useHistory();

  const handleWeightChange = (event) => {
    let value = event.target.value === "" ? "" : Number(event.target.value);
    value = value < 1 ? 1 : value;
    value = value > 500 ? 500 : value;
    const dirty = value !== weight.initial;
    setWeight({
      value: value,
      dirty: dirty,
      initial: weight.initial,
    });
  };

  const updateDescription = async (newDesc) => {
    const categoriesColl = db.collection("categories");
    try {
      await categoriesColl.updateOne(
        { _id: decodeURIComponent(currCategory) },
        { $set: { description: newDesc } }
      );
      setDescription({
        dirty: false,
        value: newDesc,
        initial: newDesc,
      });
      setSnackbar({
        children: "Description successfully updated",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setErrors([...errors, { error }]);
    }
  };

  const updateWeight = async (newWeight) => {
    const categoriesColl = db.collection("categories");
    try {
      await categoriesColl.updateOne(
        { _id: decodeURIComponent(currCategory) },
        { $set: { weight: newWeight } }
      );
      setWeight({
        dirty: false,
        value: newWeight,
        initial: newWeight,
      });
      setSnackbar({
        children: "Weight successfully updated",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setErrors([...errors, { error }]);
    }
  };

  const handleResourceChange = async (changedField, idx, value) => {
    const tmpResources = JSON.parse(JSON.stringify(resources));
    tmpResources.value[idx][changedField] = value;
    if (
      JSON.stringify(tmpResources.value) !==
      JSON.stringify(tmpResources.initial)
    ) {
      tmpResources.dirty = true;
    } else {
      tmpResources.dirty = false;
    }

    setResources(tmpResources);
  };

  const updateResources = async () => {
    const categoriesColl = db.collection("categories");
    try {
      await categoriesColl.updateOne(
        { _id: decodeURIComponent(currCategory) },
        { $set: { resources: resources.value } }
      );
      setResources({
        dirty: false,
        value: resources.value,
        initial: resources.value,
      });
      setSnackbar({
        children: "Resources successfully updated",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setErrors([...errors, { error }]);
    }
  };

  const deleteResource = async (resource, idx) => {
    const categoriesColl = db.collection("categories");
    try {
      await categoriesColl.updateOne(
        { _id: decodeURIComponent(currCategory) },
        {
          $pull: {
            resources: {
              text: resource.text,
              url: resource.url,
            },
          },
        }
      );
      resources.value.splice(idx, 1);
      setResources({
        dirty: false,
        value: resources.value,
        initial: resources.value,
      });
      setSnackbar({
        children: "Resources successfully updated",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setErrors([...errors, { error }]);
    }
  };

  useEffect(() => {
    if (!loading) {
      if (!currentUser.customData.is_admin) {
        setSnackbar({
          children: "Unauthorized user",
          severity: "error",
        });
        history.push("/");
      } else {
        const categoriesColl = db.collection("categories");
        categoriesColl
          .findOne({ _id: decodeURIComponent(currCategory) })
          .then((results) => {
            setCategory(results);
            setWeight({
              value: results.weight,
              initial: results.weight,
              dirty: false,
            });
            setLoadingData(false);
            setDescription({
              dirty: false,
              value: results.description,
              initial: results.description,
            });
            setResources({
              dirty: false,
              value: results.resources,
              initial: results.resources,
            });
          });
      }
    }
  }, [
    loading,
    db,
    currCategory,
    currentUser?.customData?.is_admin,
    setSnackbar,
    history,
  ]);

  return (
    <>
      <Container
        sx={{
          pt: 4,
          height: "90vh",
        }}
        maxWidth="lg"
      >
        <Grid
          container
          display="flex"
          justifyContent="start"
          alignItems="start"
          spacing={2}
        >
          {loadingData ? (
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid
                item
                display="flex"
                justifyContent="flex-start"
                alignItems="start"
              >
                <Breadcrumbs sx={{ color: "#001e2b" }} aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    color="#001e2b"
                    component={RouterLink}
                    to="/"
                  >
                    Home
                  </Link>
                  <Link
                    underline="hover"
                    color="#001e2b"
                    component={RouterLink}
                    to="/admin"
                  >
                    Admin
                  </Link>
                  <Link
                    underline="hover"
                    color="#001e2b"
                    component={RouterLink}
                    to={`/admin/${category._id}`}
                  >
                    {category._id}
                  </Link>
                </Breadcrumbs>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <Typography variant="h3" gutterBottom>
                  {category._id}
                </Typography>
              </Grid>

              <>
                {errors.length > 0 &&
                  errors.map((error) => {
                    return (
                      <Grid item xs={12} key={error}>
                        <Alert severity="error">
                          <AlertTitle>
                            {/* Heard you like errors so I put errors in your errors so you can error while you error */}
                            Error! {error.error.statusCode}:{" "}
                            {error.error.error || "Something went wrong"}
                          </AlertTitle>
                        </Alert>
                      </Grid>
                    );
                  })}

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        display="flex"
                        justifyContent="start"
                        spacing={2}
                      >
                        {" "}
                        <TitleWithToolTip
                          title="Description"
                          tooltip="Description will be shown on the Customer page under the category"
                        ></TitleWithToolTip>
                        <Grid item xs={12}>
                          <TextField
                            id="outlined-multiline-flexible"
                            sx={{ minWidth: "100%" }}
                            multiline
                            required
                            maxRows={4}
                            defaultValue={description.value}
                            error={errors.length > 0}
                            onChange={(event) => {
                              setDescription({
                                dirty:
                                  event.target.value !== description.initial,
                                value: event.target.value,
                                initial: description.initial,
                              });
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Button
                            disabled={!description.dirty || errors.length > 0}
                            variant="contained"
                            color="mdbGreen"
                            onClick={() => {
                              updateDescription(description.value);
                            }}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        display="flex"
                        justifyContent="start"
                        alignItems="start"
                        spacing={2}
                      >
                        <TitleWithToolTip
                          title="Weight"
                          tooltip="How much importance should this category be given when calculating a customer's maturity score?"
                        ></TitleWithToolTip>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            border: "1px solid rgba(0,0,0,0.2)",
                            borderBottomLeftRadius: "4px",
                            borderBottomRightRadius: "4px",
                            borderTopRightRadius: "4px",
                            borderTopLeftRadius: "4px",
                            m: 1,
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            sx={{
                              border: errors.length > 0 ? "1px solid red" : 0,
                            }}
                          >
                            <Grid item xs>
                              <Slider
                                min={1}
                                max={500}
                                value={weight.value}
                                onChange={handleWeightChange}
                                aria-labelledby="input-slider"
                              />
                            </Grid>
                            <Grid item>
                              <Input
                                key={category.name}
                                value={weight.value}
                                size="small"
                                onChange={handleWeightChange}
                                error={errors.length > 0}
                                inputProps={{
                                  min: 1,
                                  max: 500,
                                  type: "number",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Button
                            disabled={!weight.dirty || errors.length > 0}
                            variant="contained"
                            color="mdbGreen"
                            onClick={() => {
                              updateWeight(weight.value);
                            }}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} display="flex" alignItems="center">
                  <Card sx={{ minWidth: "100%" }}>
                    <CardContent>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-between"
                      >
                        <TitleWithToolTip
                          title="Resources"
                          tooltip="The resources/playbooks available as suggested actions after filling out a survey"
                        ></TitleWithToolTip>
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            color="mdbGreen"
                            onClick={() => {
                              const newval = { text: "", url: "" };
                              resources.value.push(newval);
                              setResources({
                                dirty: true,
                                value: resources.value,
                              });
                            }}
                          >
                            Add Resource
                          </Button>
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Grid item xs={5} display="flex">
                            <Typography variant="h6">URL</Typography>
                          </Grid>
                          <Grid item xs={5} display="flex">
                            <Typography variant="h6">Title</Typography>
                          </Grid>
                          <Grid item xs={1} display="flex"></Grid>

                          {category &&
                            category.resources.map((resource, idx) => {
                              return (
                                <Grid
                                  container
                                  xs={12}
                                  display="flex"
                                  justifyContent="space-between"
                                  key={`${resource.text}-${idx}`}
                                >
                                  <Grid item xs={5}>
                                    <TextField
                                      id="outlined-multiline-flexible"
                                      sx={{ minWidth: "100%" }}
                                      required
                                      defaultValue={resource.url}
                                      error={errors.length > 0}
                                      onChange={(event) => {
                                        handleResourceChange(
                                          "url",
                                          idx,
                                          event.target.value
                                        );
                                      }}
                                    ></TextField>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <TextField
                                      id="outlined-multiline-flexible"
                                      sx={{ minWidth: "100%" }}
                                      required
                                      defaultValue={resource.text}
                                      error={errors.length > 0}
                                      onChange={(event) => {
                                        handleResourceChange(
                                          "text",
                                          idx,
                                          event.target.value
                                        );
                                      }}
                                    ></TextField>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Button
                                      color="error"
                                      variant="contained"
                                      onClick={() =>
                                        deleteResource(resource, idx)
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <Button
                              disabled={!resources.dirty || errors.length > 0}
                              variant="contained"
                              color="mdbGreen"
                              onClick={() => {
                                updateResources();
                              }}
                            >
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-between"
                      >
                        <TitleWithToolTip
                          title="Questions"
                          tooltip="Survey questions posed for this category"
                        ></TitleWithToolTip>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Table
                            sx={{ minWidth: 250, color: "#001E2B" }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ color: "#001E2B" }}>
                                  <Typography variant="h6">Text</Typography>
                                </TableCell>
                                <TableCell
                                  sx={{ color: "#001E2B" }}
                                  align="right"
                                ></TableCell>
                                <TableCell
                                  sx={{ color: "#001E2B" }}
                                  align="right"
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {category &&
                                category.questions.map((question) => {
                                  return (
                                    <TableRow
                                      key={question._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          color: "#001E2B",
                                          fontSize: "1.1em",
                                        }}
                                        component="th"
                                        scope="row"
                                      >
                                        {question.text}
                                      </TableCell>
                                      <TableCell>
                                        <Button
                                          variant="contained"
                                          color="mdbGreen"
                                          size="small"
                                          onClick={() => {
                                            history.push(
                                              `/admin/${encodeURIComponent(
                                                category._id
                                              )}/${encodeURIComponent(
                                                question._id
                                              )}`
                                            );
                                          }}
                                        >
                                          Edit
                                        </Button>
                                      </TableCell>
                                      <TableCell>
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            alert("Not implemented");
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
