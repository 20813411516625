import AccountCircle from "@mui/icons-material/AccountCircle";
import { Alert, Button, Snackbar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { useRealmUserContext } from "../contexts/RealmContext";

export default function AppComp() {
  const { currentUser, snackbar, setSnackbar } = useRealmUserContext();

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <Box>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar
          sx={{
            width: "100vw",
          }}
        >
          <Box sx={{ width: "20%" }}>
            <Link component={RouterLink} to="/">
              <img
                alt="MongoDB Logo"
                style={{
                  maxWidth: "175px",
                  display: "flex",
                }}
                src={`${"/assets/MongoDB_White.png"}`}
              />
            </Link>
          </Box>
          <Box sx={{ width: "75%", textAlign: "center" }}>
            <Typography variant="h4" color="white">
              Athena Customer Journey Dashboard
            </Typography>
          </Box>{" "}
          <Box sx={{ width: "10%", textAlign: "center" }}>
            {currentUser?.customData?.is_admin && (
              <Button
                component={RouterLink}
                to={"/admin"}
                variant="outlined"
                color="error"
                display="flex"
                sx={{ justifyItems: "right", backgroundColor: "white" }}
              >
                Admin
              </Button>
            )}
          </Box>
          <Box sx={{ width: "14%", ml: 1 }}>
            {currentUser && (
              <>
                <Typography
                  variant="body.1"
                  display="flex"
                  sx={{ alignItems: "center", justifyContent: "right" }}
                >
                  <AccountCircle sx={{ mr: 1, alignItems: "center" }} />
                  {currentUser.profile.name}
                </Typography>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}
