import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useHistory } from "react-router-dom";

export default function SurveyTable({ surveys }) {
  const history = useHistory();

  if (surveys?.length > 0) {
    return (
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, color: "#001E2B" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "#001E2B" }}>Survey date</TableCell>
              <TableCell sx={{ color: "#001E2B" }} align="right">
                User
              </TableCell>
              <TableCell sx={{ color: "#001E2B" }} align="right">
                Maturity
              </TableCell>
              <TableCell sx={{ color: "#001E2B" }} align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {surveys &&
              surveys.map((survey) => {
                return (
                  <TableRow key={survey._id}>
                    <TableCell sx={{ color: "#001E2B" }}>
                      {survey.timestamp.toLocaleString()}
                    </TableCell>
                    <TableCell
                      sx={{ color: "#001E2B", paddingLeft: 50 }}
                      align="right"
                    >
                      {survey.owner}
                    </TableCell>
                    <TableCell sx={{ color: "#001E2B" }} align="right">
                      {survey.maturityScore}
                    </TableCell>
                    <TableCell sx={{ color: "#001E2B" }} align="right">
                      <Button
                        color="mdbGreen"
                        onClick={() => {
                          history.push(
                            `/${encodeURIComponent(survey.customer)}/survey/${
                              survey._id
                            }`
                          );
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
