import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import DollarAmountCard from "../components/DollarAmountCard";
import MaturityMatrixCard from "../components/MaturityMatrixCard";
import MaturityStageCard from "../components/MaturityStageCard";
import PercentageCard from "../components/PercentageCard";
import SuggestedActionsCard from "../components/SuggestedActionsCard";
import SurveyHistoryCard from "../components/SurveyHistoryCard";
import SurveyTable from "../components/SurveyTable";
import configData from "../config.json";
import { useRealmUserContext } from "../contexts/RealmContext";
import { getMaturityStage, stringToTitleCase } from "../helpers";

const StyledAccordionSumary = styled(AccordionSummary)({
  ".MuiAccordionSummary-content": {
    justifyContent: "center",
  },
});

export default function Customer() {
  const { currentUser, loading, db, maturityMappings } = useRealmUserContext();

  const [customerId] = useState(decodeURIComponent(useParams().customerId));

  const [loadingData, setLoadingData] = useState(true);

  const [customer, setCustomer] = useState();
  const [owner, setOwner] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [maturityStage, setMaturityStage] = useState();
  const [maturityScore, setMaturityScore] = useState();

  const history = useHistory();

  useEffect(() => {
    const fetchCustomers = async () => {
      const customersColl = db.collection(configData.CUSTOMERS_COLLECTION);
      const cust = await customersColl.findOne({ sfdc_account_id: customerId });
      setCustomer({
        ...cust.metrics,
        owner: cust.owner,
        name: cust.name,
        owner_manager: cust.owner_manager,
        csm: cust.csm,
        sfdc: cust.sfdc,
        parent_sfdc: cust?.sfdc_parent_id,
        parent_name: cust?.sfdc_parent_name,
      });
    };
    if (!loading) {
      fetchCustomers();
    }
  }, [loading, customerId, db]);

  useEffect(() => {
    const analyzeSurveys = async () => {
      const results = await currentUser.functions.analyzeSurveys(
        customerId,
        false
      );
      setSurveys(results);
      setMaturityScore(Math.round(results[0]?.maturityScore) || -1);
      setLoadingData(false);
    };
    if (!loading) {
      analyzeSurveys();
    }
  }, [loading, customerId, currentUser]);

  useEffect(() => {
    const getMaturityMappings = async () => {
      const result = getMaturityStage(
        maturityMappings,
        maturityScore,
        customer
      );
      setMaturityStage(stringToTitleCase(result));
    };
    if (!loading) {
      if (maturityScore) {
        if (maturityScore < 0) {
          setMaturityStage("No data");
        } else {
          getMaturityMappings();
        }
      }
    }
  }, [loading, maturityScore, maturityMappings, customer]);

  useEffect(() => {
    if (!loading && customer) {
      if (
        [
          customer.owner.name,
          customer.owner_manager?.name,
          customer.csm?.name,
        ].includes(currentUser.profile.name)
      ) {
        setOwner(true);
      } else {
        setOwner(false);
      }
    }
  }, [customer, currentUser, loading]);

  return (
    <>
      <Container
        sx={{
          pt: 4,
          height: "90vh",
        }}
        maxWidth="lg"
      >
        {customer ? (
          <Grid
            container
            display="flex"
            justifyContent="start"
            alignItems="start"
            spacing={4}
          >
            <Grid
              item
              display="flex"
              justifyContent="flex-start"
              alignItems="start"
            >
              <Breadcrumbs sx={{ color: "#001e2b" }} aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="#001e2b"
                  component={RouterLink}
                  to="/"
                >
                  Home
                </Link>
                <Link
                  underline="hover"
                  color="#001e2b"
                  component={RouterLink}
                  to={encodeURIComponent(customerId)}
                >
                  {customer ? customer.name : ""}
                </Link>
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="start"
              alignItems="flex-end"
              paddingBottom={0}
            >
              <Typography variant="h3">
                {customer ? customer.name : ""}
              </Typography>
              {customer ? (
                <Typography pl={2} variant="subtitle2">
                  <Link href={customer.sfdc} target="_blank">
                    SFDC
                    {
                      <OpenInNewIcon
                        sx={{ color: "black", fontSize: "small" }}
                      />
                    }
                  </Link>
                </Typography>
              ) : (
                ""
              )}
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="start"
              alignItems="start"
              paddingTop={0}
              paddingBottom={0}
            ></Grid>

            {customer ? (
              <>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="start"
                  alignItems="start"
                  paddingY={0}
                >
                  <Typography variant="subtitle2" mr={0.5}>
                    {"Owner:"}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: "bold" }}
                  >{`${customer.owner.name}`}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="start"
                  alignItems="start"
                  paddingY={0}
                >
                  <Typography variant="subtitle2" mr={0.5}>
                    {"CSM:"}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: "bold" }}
                  >{`${customer.csm?.name || "N/A"}`}</Typography>
                </Grid>
              </>
            ) : (
              ""
            )}
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="start"
              alignItems="start"
              paddingTop={0}
            >
              {customer?.parent_sfdc ? (
                <>
                  <Typography variant="subtitle2">
                    {"Ultimate parent account: "}
                    <Link href={customer.parent_sfdc}>
                      {customer.parent_name}
                    </Link>
                  </Typography>
                </>
              ) : (
                ""
              )}
            </Grid>

            {loadingData ? (
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <DollarAmountCard
                    value={customer?.adjusted_tamm || 0}
                    title="TAMM"
                    tooltip="Total addressable MongoDB market"
                  />
                  <DollarAmountCard
                    value={customer?.arr || 0}
                    title="ARR"
                    tooltip="The customers total annual run rate on MongoDB"
                  />
                  <PercentageCard
                    tamm={customer?.adjusted_tamm}
                    arr={customer?.arr}
                    title="Account Penetration"
                    tooltip="Percentage penetration of MongoDB in the account"
                  />
                </Grid>
                {surveys.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <MaturityStageCard
                        value={maturityScore || 0}
                        stage={maturityStage}
                        maturityMappings={maturityMappings}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MaturityMatrixCard
                        maturityMatrix={surveys[0].responses}
                      />
                    </Grid>
                    <Grid xs={12} display="flex" item>
                      <SuggestedActionsCard
                        maturityStage={maturityStage}
                        latestSurvey={surveys[0]}
                        maturityMappings={maturityMappings}
                      ></SuggestedActionsCard>
                    </Grid>
                    <Grid xs={12} display="flex" item>
                      <Accordion sx={{ minWidth: "100%" }}>
                        <StyledAccordionSumary
                          expandIcon={
                            <ExpandMoreIcon sx={{ color: "white" }} />
                          }
                          sx={{ backgroundColor: "#00684A", display: "flex" }}
                        >
                          <Typography
                            sx={{ color: "white", display: "flex" }}
                            variant="h4"
                          >
                            Survey history
                          </Typography>
                        </StyledAccordionSumary>
                        <AccordionDetails>
                          <Grid xs={12} display="flex" item>
                            <SurveyHistoryCard surveys={surveys} />
                          </Grid>
                          <Grid xs={12} display="flex" item>
                            <SurveyTable surveys={surveys} />
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </>
                )}

                <Grid
                  lg={12}
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="between"
                >
                  {owner ? (
                    <Button
                      variant="contained"
                      color="mdbGreen"
                      onClick={() => {
                        history.push(
                          `/${encodeURIComponent(customerId)}/survey/`
                        );
                      }}
                    >
                      Run new survey
                    </Button>
                  ) : (
                    <Typography
                      sx={{ color: "#001e2b" }}
                      variant="body.1"
                      gutterBottom
                    >
                      You are not the owner of this customer, and can't run a
                      survey.
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          <>{!loadingData ? <h2>Customer not found</h2> : ""}</>
        )}
      </Container>
    </>
  );
}
