import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import moment from "moment";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import AccountSearch from "../components/AccountSearch";
import AccountTable from "../components/AccountTable";
import configData from "../config.json";
import { useRealmUserContext } from "../contexts/RealmContext";
import { getMaturityStage } from "../helpers";

export default function HomePage() {
  const [loadingData, setLoadingData] = useState(true);
  const { currentUser, loading, maturityMappings, db } = useRealmUserContext(); // Realm context
  const [customers, setCustomers] = useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState("");

  useEffect(() => {
    const fetchStage = async (customer) => {
      const results = await currentUser.functions.analyzeSurveys(
        customer.sfdc_account_id,
        true
      );
      const matScore = results[0]?.maturityScore || -1;
      // setMaturityScore(matScore);
      if (matScore < 0) {
        return "N/A";
      }

      const matStage = await getMaturityStage(
        maturityMappings,
        Math.round(matScore),
        customer.metrics
      );
      customer.maturityScore = matScore;
      customer.maturityStage = matStage;
      return matStage;
    };

    const fetchCustomers = async () => {
      const customersColl = db.collection(configData.CUSTOMERS_COLLECTION);
      const results = await customersColl.find({
        $or: [
          { "owner.name": currentUser.profile.name },
          { "owner_manager.name": currentUser.profile.name },
          { "csm.name": currentUser.profile.name },
        ],
      });
      return results;
    };

    const updateCustomers = async () => {
      const customerArray = await fetchCustomers();
      const customerPromises = await customerArray.map(async (cust) => {
        return {
          ...cust,
          maturityStage: await fetchStage(cust),
        };
      });
      const results = await Promise.all(customerPromises);
      setCustomers(results);
      setLoadingData(false);
    };

    const getLastUpdateTimestamp = async () => {
      const syncLogColl = db.collection("syncLog");
      const result = await syncLogColl.findOne(
        {},
        { sort: { timestamp: -1 }, limit: 1 }
      );
      setLastUpdatedAt(result.timestamp);
    };

    if (!loading && maturityMappings) {
      updateCustomers();
      getLastUpdateTimestamp();
    }
  }, [
    loading,
    db,
    setLoadingData,
    currentUser?.profile.name,
    currentUser?.functions,
    maturityMappings,
  ]);

  return (
    <>
      <Container
        sx={{
          pt: 4,
          height: "90vh",
        }}
        maxWidth="lg"
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="start"
            alignItems="start"
          ></Grid>
          {loadingData ? (
            <>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            </>
          ) : (
            <>
              <Grid>
                <Typography sx={{ color: "#001e2b" }} variant="p">
                  Accounts you own are shown below, or you can use the search
                  bar to find other customers.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="start"
                alignItems="start"
              >
                <AccountSearch />
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AccountTable loadingData={loadingData} customers={customers} />
          </Grid>
          {lastUpdatedAt ? (
            <Grid>
              <Tooltip title={moment(lastUpdatedAt).toISOString()}>
                <Typography sx={{ fontSize: "small" }}>
                  {"Last data refresh: "}
                  {lastUpdatedAt ? (
                    <Moment fromNow>{lastUpdatedAt}</Moment>
                  ) : (
                    "N/A"
                  )}
                </Typography>
              </Tooltip>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Container>
    </>
  );
}
