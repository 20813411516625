import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import * as Realm from "realm-web";
import { useRealmUserContext } from "../contexts/RealmContext";

export default function EditQuestionPage() {
  const { loading, db, setSnackbar, currentUser } = useRealmUserContext();

  const history = useHistory();
  const currCategory = useParams().categoryId;
  const currQuestion = useParams().questionId;
  const [errors, setErrors] = useState([]);

  const [category, setCategory] = useState();
  const [loadingData, setLoadingData] = useState(true);

  const [question, setQuestion] = useState({ dirty: false });

  const copyQuestionObject = (questionObj) => {
    const tmpObj = JSON.parse(JSON.stringify(questionObj));
    tmpObj._id = new Realm.BSON.ObjectID(tmpObj._id);
    return tmpObj;
  };

  const updateQuestionText = async (newQuestion) => {
    const categoriesColl = db.collection("categories");
    try {
      await categoriesColl.updateOne(
        { _id: category._id },
        { $set: { "questions.$[x]": newQuestion.value } },
        {
          arrayFilters: [
            { "x._id": new Realm.BSON.ObjectID(newQuestion.value._id) },
          ],
        }
      );
      setQuestion({
        value: question.value,
        initial: await copyQuestionObject(question.value),
        dirty: false,
      });

      setSnackbar({
        children: "Description successfully updated",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setErrors([...errors, { error }]);
      setSnackbar({
        children: "Something went wrong",
        severity: "error",
      });
    }
  };

  const handleQuestionChange = async (changedField, value) => {
    const tmpValue = JSON.parse(JSON.stringify(value));
    question.value[changedField] = tmpValue;

    const tmpQuestionValue = { ...question.value, [changedField]: tmpValue };
    if (JSON.stringify(question.value) !== JSON.stringify(question.initial)) {
      question.dirty = true;
    } else {
      question.dirty = false;
    }
    await setQuestion({ ...question, value: tmpQuestionValue });
  };

  const handleAnswerChange = async (answerLevel, value) => {
    const currentAnswers = question.value.possibleAnswers;
    const updatedIndex = currentAnswers.findIndex(
      (item) => item.answerLevel === answerLevel
    );
    currentAnswers[updatedIndex] = { answerLevel, answerText: value };
    handleQuestionChange("possibleAnswers", currentAnswers);
  };

  useEffect(() => {
    if (!loading) {
      if (!currentUser.customData.is_admin) {
        setSnackbar({
          children: "Unauthorized user",
          severity: "error",
        });
        history.push("/");
      } else {
        const categoriesColl = db.collection("categories");
        categoriesColl
          .findOne({ _id: decodeURIComponent(currCategory) })
          .then((results) => {
            const question = results.questions.filter(
              (question) => question._id.toString() === currQuestion
            )[0];
            setCategory(results);
            setQuestion({
              dirty: false,
              value: question,
              initial: copyQuestionObject(question),
            });
            setLoadingData(false);
          });
      }
    }
  }, [
    currCategory,
    currQuestion,
    currentUser?.customData?.is_admin,
    db,
    history,
    loading,
    setSnackbar,
  ]);

  return (
    <Container
      sx={{
        pt: 4,
        height: "90vh",
      }}
      maxWidth="lg"
    >
      <Grid
        container
        display="flex"
        justifyContent="start"
        alignItems="start"
        spacing={2}
      >
        {loadingData ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid
              item
              display="flex"
              justifyContent="flex-start"
              alignItems="start"
            >
              <Breadcrumbs sx={{ color: "#001e2b" }} aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="#001e2b"
                  component={RouterLink}
                  to="/"
                >
                  Home
                </Link>
                <Link
                  underline="hover"
                  color="#001e2b"
                  component={RouterLink}
                  to="/admin"
                >
                  Admin
                </Link>
                <Link
                  underline="hover"
                  color="#001e2b"
                  component={RouterLink}
                  to={`/admin/${category._id}`}
                >
                  {category._id}
                </Link>
                <Link
                  underline="hover"
                  color="#001e2b"
                  component={RouterLink}
                  to={`/admin/${category._id}/${question?.value?._id}`}
                >
                  {`Question ${question?.value?._id}`}
                </Link>
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="start"
              alignItems="start"
            >
              <Typography variant="h3" gutterBottom>
                {category._id}
              </Typography>
            </Grid>
            {question?.value && (
              <>
                <Grid item xs={12} key={question.value._id}>
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        display="flex"
                        justifyContent="start"
                        spacing={2}
                      >
                        <Grid item>
                          <Typography variant="h5">Question</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            key={question.value._id}
                            id="outlined-multiline-flexible"
                            sx={{ minWidth: "100%" }}
                            multiline
                            required
                            maxRows={4}
                            defaultValue={question.value.text}
                            onChange={(event) => {
                              handleQuestionChange("text", event.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Possible answers</Typography>
                      {question &&
                        question.value.possibleAnswers.map((answer) => {
                          return (
                            <Grid
                              key={answer.answerLevel}
                              item
                              xs={12}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <TextField
                                id="outlined-multiline-flexible"
                                sx={{ minWidth: "100%" }}
                                multiline
                                required
                                maxRows={4}
                                defaultValue={answer.answerText}
                                onChange={(event) => {
                                  handleAnswerChange(
                                    answer.answerLevel,
                                    event.target.value
                                  );
                                }}
                              />
                            </Grid>
                          );
                        })}
                    </CardContent>
                  </Card>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Button
                disabled={!question.dirty || errors.length > 0}
                variant="contained"
                color="mdbGreen"
                onClick={() => {
                  updateQuestionText(question);
                }}
              >
                Save
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
}
