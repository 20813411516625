import { InfoOutlined } from "@mui/icons-material";
import { Grid, Tooltip, Typography } from "@mui/material";

export default function TitleWithToolTip({ title, tooltip }) {
  return (
    <Grid item xs={12} display="flex" alignItems="center">
      <Typography variant="h5">{title}</Typography>
      <Tooltip title={tooltip} placement="top" arrow enterTouchDelay={100}>
        <InfoOutlined color="mdbGreen" sx={{ ml: 1, mt: 0.5 }} />
      </Tooltip>
    </Grid>
  );
}
