import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import React, { useEffect } from "react";

export default function CategoryCard({
  category,
  handleAnswers,
  answers,
  disabled,
}) {
  const [expanded, setExpanded] = React.useState("panel1");
  const [value, setValue] = React.useState("");
  const [helperText, setHelperText] = React.useState(
    "Select an answer that best describes the current state."
  );

  const handleRadioChange = (event) => {
    if (!disabled) {
      handleAnswers({
        category: category._id,
        answers: [event.target.value],
      });
      setValue(event.target.value);
      setHelperText(" ");
      setExpanded(false);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (answers?.responses.length > 0) {
      var answer = answers.responses.filter(
        (entry) => entry.category === category._id
      );

      if (answer[0]?.answers[0]) {
        setValue(answer[0].answers[0]);
      }
    }
  }, [answers, category._id]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              key={category._id}
            >
              <Typography variant="h5">{category._id}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {category.questions.map((question) => {
                return (
                  <Grid key={category._id} container>
                    <Grid item xs={12}>
                      <Typography variant="h7">Q: {question.text}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <form>
                        <FormControl variant="standard">
                          <RadioGroup
                            aria-labelledby="demo-error-radios"
                            name="quiz"
                            value={value}
                            onChange={handleRadioChange}
                          >
                            {question.possibleAnswers.map((answer) => {
                              return (
                                <FormControlLabel
                                  sx={{
                                    border: "1px dashed rgba(0, 0, 0, 0.1)",
                                    p: 2,
                                  }}
                                  key={answer.answerLevel}
                                  value={answer.answerLevel}
                                  control={<Radio />}
                                  label={answer.answerText}
                                />
                              );
                            })}
                          </RadioGroup>
                          <FormHelperText>{helperText}</FormHelperText>
                        </FormControl>
                      </form>
                    </Grid>
                  </Grid>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
}
