import { InfoOutlined } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import React from "react";
import { calculatePenetration } from "../helpers";

export default function DollarAmountCard({ tamm, arr, title, tooltip }) {
  return (
    <Grid item xs={4} justifyContent="center" alignItems="center">
      <Card sx={{ minWidth: 275, display: "flex" }}>
        <CardContent sx={{ flex: 1 }}>
          <Box
            sx={{
              height: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#001e2b",
                display: "flex",
                alignItems: "center",
              }}
              variant="body.1"
            >
              {title}
              <Tooltip
                title={tooltip}
                placement="top"
                arrow
                enterTouchDelay={100}
              >
                <IconButton>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </Typography>
          </Box>
          <Box
            sx={{
              height: "50%",
              display: "flex",
              alignItems: "top",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ color: "#001e2b" }} variant="h4">
              {calculatePenetration(tamm, arr)}%
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
